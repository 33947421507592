import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './Home.js';
import Contact from './ContactUs.js';
import OurCompany from './OurCompany.js';
import Services from './Services.js';
import './App.css';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <nav className="App-nav">
            <div className="App-logo">
              <Link to="/" className="LogoStyle">SkysSoft Solutions</Link>
            </div>
            <button className="menu-toggle" onClick={toggleMenu}>
              ☰
            </button>
            <ul className={`App-nav-links ${menuOpen ? 'active' : ''}`}>
              <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
              <li><Link to="/OurCompany" onClick={toggleMenu}>Our Company</Link></li>
              <li><Link to="/Services" onClick={toggleMenu}>Services</Link></li>
              <li><Link to="/Contact" onClick={toggleMenu}>Contact Us</Link></li>
            </ul>
          </nav>
        </header>
        
        <div className="App-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/OurCompany" element={<OurCompany />} />
            <Route path="/Services" element={<Services />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
